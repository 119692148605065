import React, { Component } from 'react';
import { Badge, Card, CardBody, CardFooter, CardHeader, Col, Row, Collapse, Fade, FormGroup, Label, Input, Form, Button, FormText, Table, Pagination, PaginationItem, PaginationLink, } from 'reactstrap';
import DataService from '../../../services/dataservice';
import Barcode from 'react-barcode'
import moment from 'moment'
import _ from 'lodash'
// import { AppSwitch } from '@coreui/react'


class PreviewPrintf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      productStore: []
    };

  }
  componentDidMount() {
    this.getBill()
    this.getStoreProduct()
    
  }
  async getBill() {
    let id = this.props.match.params.id
    let where = {
      id: +id
    }
    let populate = 'billDetailIds'
    let rs = await DataService.getBill(0, 50, populate, '', where, '', 3)
    if (rs.status == 200) {
      this.setState({
        data: rs.data.data[0]
      })
    }
    await window.print()
    console.log('getbill', rs)
  }
  async getStoreProduct() {
    let storeID = JSON.parse(localStorage.getItem('stinfo'))
    let rs = await DataService.getStoreProduct(storeID.storeId, localStorage.getItem('userTK'), 1)

    if (rs.status == 200) {
      this.setState({ productStore: rs.data.data })
    }
  }
  render() {
    console.log(this.state)
    let listProduct
    let newList = []
    let voucherType = ''
    if (!_.isEmpty(this.state.data)) {
      if (this.state.data.voucherType == 'custom') voucherType = 'Discount'
      if (this.state.data.voucherType == 'mpoint') voucherType = 'Busines Class'

      listProduct = this.state.data.billDetailIds
      if (!_.isEmpty(this.state.productStore)) {
        listProduct.map(d => {
          let filter = this.state.productStore.filter(v => v.id == d.productId)
          if (filter[0]) newList.push(filter[0])
        })
      }
      console.log(newList)
    }
    let storeInfo = JSON.parse(localStorage.getItem('stinfo'))
    return (
      <div id="section-to-print" style={{ color: 'black', backgroundColor: 'white', maxWidth: '320px', marginLeft: '20pt' }}>
        <div style={{ textAlign: 'center' }}> <img src='/img-logo-blackwhite.jpg' style={{ width: 'auto', height: '155px' }} /></div>
        <div style={{ fontSize: '35px', textAlign: 'center' }} >Tiệm Giặt Ký </div>
        <table style={{marginLeft:'64pt',minWidth:'125pt'}}>
          <thead></thead>
          <tbody>
            <tr>
              <td>Địa chỉ:</td>
              <td>{storeInfo.address}</td>
            </tr>
            <tr>
              <td>Hot-line:</td>
              <td>{storeInfo.phone}</td>
            </tr>
          </tbody>
        </table>
        <div style={{ fontSize: '20px', textAlign: 'center' }} >https://likewash.com </div>
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <span style={{fontSize:'20px'}}>Mã hóa đơn: {!_.isEmpty(this.state.data) ? this.state.data.id : ''}</span>
        </div>
        <div style={{ width: 'auto', minHeight: '30px', textAlign: 'center' }}>
          <Barcode width={2} value={!_.isEmpty(this.state.data) ? this.state.data.barCode : ''}></Barcode>
        </div>
        <div style={{}}>
          <table style={{ marginLeft: '10pt' }}>
            <tr>
              <th></th>
            </tr>
            <tr>
              <td>Tên khách hàng:</td>
              <td>{!_.isEmpty(this.state.data) ? this.state.data.customerName : ''}</td>
            </tr>
            <tr>
              <td>Điện thoại:</td>
              <td>{!_.isEmpty(this.state.data) ? this.state.data.customerPhone : ''}</td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>{!_.isEmpty(this.state.data) ? this.state.data.email : ''}</td>
            </tr>
            <tr>
              <td>Loại voucher:</td>
              <td>{voucherType} </td>
            </tr>
            <tr>
              <td>Mã voucher:</td>
              <td>{!_.isEmpty(this.state.data) ? this.state.data.voucher : ''}</td>
            </tr>
            <tr>
              <td>Địa chỉ đăng ký nhận đồ:</td>
              <td>{!_.isEmpty(this.state.data) ? this.state.data.receivedAddress : ''}
              </td>
            </tr>
            <tr>
              <td><strong>Tình trạng thanh toán:</strong></td>
              <td><strong>{this.state.data.isPay ? 'Đã thanh toán' : 'Chưa thanh toán'}</strong></td>
            </tr>
          </table>

          <table style={{ minWidth: '300px', marginBottom: '15px', marginLeft: '10pt' }}>
            <tr>
              <th style={{ borderBottom: '1px solid #ddd' }}>#</th>
              <th style={{ borderBottom: '1px solid #ddd' }}>Tên sản phẩm</th>
              <th style={{ borderBottom: '1px solid #ddd' }}>Đơn giá</th>
            </tr>
            {newList ? newList.map((d, index) => {
              return (
                <tr>
                  <td style={{ borderBottom: '1px solid #ddd' }}>{index}</td>
                  <td style={{ borderBottom: '1px solid #ddd' }}>{d.name}</td>
                  <td style={{ borderBottom: '1px solid #ddd' }}>{d.price}</td>
                </tr>
              )
            }) : ''}
          </table >
          <div style={{ width: 'auto', marginTop: '5px', marginLeft: '10pt', marginBottom: '5px' }}><strong>Ghi chú:&nbsp;</strong>{this.state.note}</div>
          <div style={{ marginLeft: '40pt', textAlign: 'center', borderTop: '1px solid black', width: '80%' }}>
            <table style={{ minWidth: '250px' }}>
              <tr>
                <th></th>
              </tr>
              <tr>
                <td><strong>Tổng tiền</strong></td>
                <td>{!_.isEmpty(this.state.data)?this.state.data.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."):'0'}</td>
              </tr>
              <tr>
                <td><strong>Giảm giá</strong></td>
                <td>{!_.isEmpty(this.state.data)? (this.state.data.totalPrice - this.state.data.needPay).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
              </tr>
              <tr>
                <td><strong>Thanh toán</strong></td>
                <td>{!_.isEmpty(this.state.data)? this.state.data.needPay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'}</td>
              </tr>
              <tr>
                <td><strong>Ngày trả đồ:</strong></td>
                <td><strong>{moment(this.state.data.dueDate).format('HH:mm DD-MM-YYYY')}</strong></td>
              </tr>
            </table>
          </div>
          <div style={{ textAlign: 'center', fontStyle: 'italic', fontSize: '30px', marginTop: '30px' }}>Hân hạnh được phục vụ quý khách</div>
        </div>
      </div>
    );
  }
}
export default PreviewPrintf;
