import request from '../services/request';
import config from '../services/config';
import api from "../services/index"
import request2 from './request2'
import socketio from './socketio'
import moment from 'moment'
var dataService = {
  actFetchVouchersRequest: (skip, limit, select, where, sort, token) => {
    let url = "api/voucher";
    let apiVersion = 5;
    let data = { skip, limit, select, where, sort }
    return request.get(url, data, token, apiVersion).then(res => {
      if (res.status === 200) {
        api.actFetchVouchers(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  actUpdateVoucherRequest: (voucher, token) => {

    let url = `api/voucher/${voucher.id}`
    return request.patch(url, voucher, token, 5).then(res => {
      if (res.status === 200) {
        return api.actUpdateVoucher(res.data)
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actAddVoucherRequest: (voucher, token) => {
    let url = 'api/voucher'
    return request.posts(url, voucher, token, 5).then(res => {

      if (res.status === 200) {
        return api.actAddVoucher(res.data)
      }
      // console.log(res.statusText)
      // this.props.history.push('#/400')
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },
  actFetchVouchersCleanIDRequest: (skip, limit, select, where, sort, token) => {
    console.log(where, 111)
    let url = "api/vouchercleanid";
    let apiVersion = 5;
    let data = { skip, limit, select, where, sort }
    return request.get(url, data, token, apiVersion).then(res => {
      if (res.status === 200) {
        api.actFetchVouchersCleanID(res.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  actAddVoucherCleanIdRequest: (voucher, token) => {
    let url = 'api/voucher-clean-id/voucher-create'
    return request.posts(url, voucher, token, 5).then(res => {

      if (res.status === 200) {
        return api.actAddVoucherCleanId(res.data)
      }
      // console.log(res.statusText)
      // this.props.history.push('#/400')
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },
  actUpdateVoucherCleanIdRequest: (voucher, token) => {

    let url = `api/vouchercleanid/${voucher.id}`
    return request.patch(url, voucher, token, 5).then(res => {
      if (res.status === 200) {
        api.showMessage('Update thành công')
        return api.actUpdateVoucherCleanID(res.data)
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },
  refreshToken(token, apiVersion) {
    let url = 'api/auth/sign-in/refresh-token'
    let data = ''
    return request.posts(url, data, token, apiVersion)
  },
  loginStore(account, password, apiVersion, token) {
    let url = 'api/auth/sign-in/store'
    let data = {
      account,
      password,
    }
    return request.posts(url, data, apiVersion, token)
  },
  loginSystem(account, password) {
    let url = 'api/auth/sign-in/user'
    let data = {
      account,
      password,
    }
    return request.posts(url, data)
  },
  loginUser(account, password, token) {
    token = localStorage.getItem('stTK');
    let url = 'api/auth/sign-in/userstore'
    let data = {
      account,
      password,
    }
    return request.posts(url, data, token)
  },
  getCustomer(skip, limit, select, where, populate, sort, token, apiVersion) {
    let url = 'api/customer'
    let data = {
      skip, limit, select, where, populate, sort, token, apiVersion
    }
    return request.get(url, data, token, apiVersion)

  },
  createCustomer(fullName, dob, phone, email, gender, address, receivedAddress, token, apiVersion) {
    let url = 'api/customer'
    let data = {
      fullName, dob, phone, email, gender, address, receivedAddress, token, apiVersion
    }
    return request.posts(url, data, token, apiVersion)
  },
  getStoreProduct(storeId, token, apiVersion) {
    let url = 'api/product-management/get-store-product'
    let data = {
      storeId
    }
    return request.get(url, data, token, apiVersion)
  },
  makeBill(customerId, customerPhone, customerName, email, voucher, voucherType, note, productIds, receivedAddress, isPay, dueDate, isPreview, isEmail, isBillSMS, isNotification, isFeedback, takeBill, firstUseApp,  token, apiVersion) {
    let url = 'api/bill-management/make-bill'
    let data = {
      customerId,
      customerPhone,
      customerName,
      email, voucher,
      voucherType,
      note,
      productIds,
      receivedAddress,
      dueDate,
      isPay,
      isPreview,
      isBillSMS,
      isNotification,
      isFeedback,
      isEmail,
      takeBill,
      firstUseApp
    }

    return request.posts(url, data, token, apiVersion)

  },
  getCustomerByID(customerId, storeInfo, token, apiVersion) {
    let url = 'api/customer-management/get-customer-info-by-id'
    let data = {
      customerId,
      storeInfo
    }
    return request.get(url, data, token, apiVersion)
  },
  logout(token, apiVerson) {
    let url = 'api/auth/logout'

    return request.posts(url, token, apiVerson)
  },
  getUser(skip, limit, populate, select, where, token, apiVersion) {
    let url = 'api/user'
    let data = {
      skip, limit, populate, select, where, token, apiVersion
    }
    return request.get(url, data, token, apiVersion)
  },
  getStoreUser(skip, limit, populate, select, where, token, apiVersion) {
    let url = 'api/storeUser'
    let data = {
      skip, limit, populate, select, where, token, apiVersion
    }
    return request.get(url, data, token, apiVersion)
  },
  updateCustomer(id, data, token, apiVersion) {
    let url = `api/customer/${id}`;
    // let data={
    //     // fullName = ,
    //     // dob,
    //     // phone,
    //     // email,
    //     // gender,
    //     // address,
    //     // receivedAddress
    // }
    return request.patch(url, data, token, apiVersion)
  },
  unblockUser(userId, token, apiVersion) {
    let url = 'api/auth/user-management/unblock-user'
    let data = { userId }
    return request.put(url, data, token, apiVersion)
  },
  blockUser(userId, token, apiVersion) {
    let url = 'api/auth/user-management/block-user'
    let data = { userId }
    return request.put(url, data, token, apiVersion)
  },
  getRole(skip, limit, populate, select, whrere, token, apiVersion) {
    let url = 'api/role'
    let data = {
      skip, limit, populate, select, whrere, token, apiVersion
    }
    return request.get(url, data, token, apiVersion)
  },
  createUser(account, password, phone, email, roleId, fullName, dob, gender, identityCard, address, description, token, apiVersion) {
    let url = 'api/auth/user-management/create-user'
    let data = {
      account,
      password,
      phone,
      email,
      roleId,
      fullName,
      dob,
      gender,
      identityCard,
      address,
      description
    }
    return request.posts(url, data, token, apiVersion)
  },
  updateUser(id, data, token, apiVersion) {
    let url = `api/user/${id}`
    return request.patch(url, data, token, apiVersion)
  },
  actGetBill(skip, limit, populate, sort, select, where) {
    let url = 'api/bill'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    else {
      url += '&sort=' + JSON.stringify([{ "id": "DESC" }])
    }
    url += '&populate=' + populate
    return request2.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }

    })
  },
  actGetBillStage(skip, limit, sort, select, where) {
    let url = 'api/billStage'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    return request2.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }

    })
  },
  actGetStorePartner(skip, limit, sort, select, where) {
    let url = 'api/storePartner'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    return request2.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }

    })
  },
  actGetProduct(skip, limit, sort, select, where) {
    let url = 'api/product'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    return request2.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage(res.data.message, 'Thông báo')
      }

    })
  },
  actChangeStage: (value) => {
    let url = 'api/bill-management/change-stage'
    return request2.callApi(url, 3, value, 'POST', null).then(res => {

      if (res.status === 200) {
        api.showMessage('Chuyển trạng thái thành công !', 'Thông báo')
        return res
      }
      else {
        api.showMessage(res.data.message, 'Thông báo')
      }
    })
  },

  actGetStore(skip, limit, select, where) {
    let url = 'api/store'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    return request2.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }

    })
  },

  actChangePasswordStore: (password) => {
    let url = 'api/auth/change-password-store'
    return request2.callApi(url, 4, password, 'PUT', null).then(res => {
      if (res.status === 200) {
        api.showMessage('Thay đổi mật khẩu thành công', 'Thông báo')
      }
      else {
        api.showMessage('Hệ thông đang gặp lỗi ! Vui lòng thử lại trong vài phút !', 'Thông báo')
      }
    })
  },
  actChangePassword: (password) => {
    let url = 'api/auth/change-password'
    return request2.callApi(url, 1, password, 'PUT', null).then(res => {
      if (res.status === 200) {
        api.showMessage('Thay đổi mật khẩu thành công', 'Thông báo')
      }
      else {
        api.showMessage(res.data.message, 'Thông báo')
      }
    })
  },

  actUpdateStore: (store) => {
    let url = `api/store/${store.id}`
    return request2.callApi(url, 4, store, 'PATCH').then(res => {
      if (res.status === 200) {
        // api.actUpdateUser(res.data)
        return res
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actGetDebts: (skip, limit, storeId, userId) => {
    let url = 'api/report/debt/debt-by-store-id'
    if (storeId) {
      url += '?storeId=' + JSON.stringify(storeId);
    }

    if (skip) {
      url += '&skip=' + (skip);
    }

    if (limit) {
      url += '&limit=' + (limit);
    }
    if (userId) {
      url += '&userId=' + (userId);
    }
    return request2.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  actGetEmailOrSms: (skip, limit, to, froms, storeId, type, smsType) => {
    let url = 'api/report/notice/sms-email-report'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('day').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('day').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (storeId && storeId !== 'undefined') {
      url += '&storeId=' + (storeId);
    }
    if (type) {
      url += '&type=' + type
    }
    if (smsType) {
      url += '&smsType=' + smsType
    }
    return request2.callApi(url, 4, null, 'GET', null).then(res => {

      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetWorkFinish: (skip, limit, to, froms, userId, storeId) => {
    let url = 'api/report/staff/amount-of-work-finish'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('day').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('day').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (storeId && storeId !== 'undefined') {
      url += '&storeId=' + (storeId);
    }
    if (userId) {
      url += '&userId=' + userId
    }
    return request2.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetTurnOver: (skip, limit, to, froms, userId, storeId) => {
    let url = 'api/report/staff/turnover'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('day').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('day').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (storeId && storeId !== 'undefined') {
      url += '&storeId=' + (storeId);
    }
    if (userId) {
      url += '&userId=' + userId
    }
    return request2.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetPartnerTurnOver: (skip, limit, to, froms, storePartnerId, storeId, fillterByStore) => {
    let url = 'api/report/partner-store/turnover'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('day').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('day').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (storeId && storeId !== 'undefined') {
      url += '&storeId=' + (storeId);
    }
    if (storePartnerId) {
      url += '&storePartnerId=' + storePartnerId
    }
    if (fillterByStore) {
      url += '&fillterByStore=' + fillterByStore
    }
    return request2.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetStoreTurnOver: (skip, limit, to, froms, where) => {
    let url = 'api/report/store/turnover'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('day').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('day').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (where && where !== 'undefined') {
      url += '&where=' + (where);
    }
    return request2.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  actGetDebtByDay: (skip, limit, apiVersion, token, from, to,storeId) => {
    let url = 'api/report/debt/debt-bill-ispay-inday'
    from = parseInt(moment(from).startOf('day').format('x'))
    to = parseInt(moment(to).endOf('day').format('x'))
    let data = {
      skip,
      limit, 
      from,
      to,
      storeId
    }
    return request.posts(url, data, apiVersion, token)
  },
  actGetOverTime: (skip, limit, to, froms, userId, storeId) => {
    let url = 'api/report/staff/overtime'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('day').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('day').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (storeId && storeId !== 'undefined') {
      url += '&storeId=' + (storeId);
    }
    if (userId) {
      url += '&userId=' + userId
    }
    return request2.callApi(url, 3, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetRating: (skip, limit, to, froms, storeId) => {
    let url = 'api/report/rating'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (to) {
      url += '&to=' + moment(to).endOf('day').valueOf();
    } else {
      url += '&to=' + (moment().endOf('month'));
    }
    if (froms) {
      url += '&from=' + moment(froms).startOf('day').valueOf()
    } else {
      url += '&from=' + (moment().startOf('month'));
    }
    if (storeId && storeId !== 'undefined') {
      url += '&storeId=' + (storeId);
    }
    return request2.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actGetCreateBill: (billId) => {
    let url = 'api/report/list-user-create-bill'
    if (billId) {
      url += '?billId=' + (billId);
    }

    return request2.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  getBill(skip, limit, populate, select, where, token, apiVersion) {
    token = localStorage.getItem('userTK')
    let url = 'api/bill'
    let data = {
      skip,
      limit,
      populate,
      select,
      where,
      token,
      apiVersion
    }
    return request.get(url, data, token, apiVersion)
  },
  deleteUser(id, token, apiVersion) {
    let url = '/api/storeUser/' + id
    return request.delete(url, token, apiVersion)
  },
  addUserToStore(storeId, userId, token, apiVersion) {
    let url = 'api/store-user-management/add-user-to-store'
    let data = { storeId, userId }
    return request.posts(url, data, token, apiVersion)
  },
  specialStorePrice(productId, price, token, apiVersion) {
    let url = 'api/product-management/specific-store-price'
    let data = {
      productId,
      price
    }
    return request.posts(url, data, token, apiVersion)
  },
  actGetSocketInit() {
    let url = '/api/socket/init'
    return socketio.callApi(url, 1, {}, 'POST').then(res => {

      sessionStorage.setItem('noticeEvent', res.body.keyEvent)
      sessionStorage.setItem('remainNotice', res.body.remainNotice)
      // api.actFetchNotifies(res.body.keyEvent)
      return res
    })
  },
  actFetchNotice: (skip, limit, select, where, sort) => {
    let url = 'api/notice'
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    // url += '&populate=' + 'voucherId,voucherUserId,billId';

    return request2.callApi(url, 1, null, 'GET', null).then(res => {

      if (res.status === 200) {
        api.actFetchNotifies(res.data.data)
        sessionStorage.setItem('remainNotice', res.data.remainNotice)
        window.refreshBell();

        return res
      }
      // console.log('yyy', res)
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchUsersRequest: (skip, limit, select, where, sort) => {
    let url = 'api/user'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'avatar';

    return request2.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFetchUsers(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Quý khách thông cảm !', 'Thông báo')
      }
    })
  },

  actGetRole: (skip, limit, select, where) => {
    let url = 'api/role'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }

    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }

    return request2.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },
  actUnBlockUser: (unblockuser) => {
    let url = 'api/auth/user-management/unblock-user'
    return request2.callApi(url, 1, unblockuser, 'PUT', null).then(res => {
      if (res.status === 200) {
        // api.showMessage('Khôi phục mật khẩu thành công !', 'Thông báo')
        return res

      }

      api.showMessage('Hệ thông đang gặp lỗi ! Vui lòng thử lại trong vài phút !', 'Thông báo')
    })
  },

  actBlockUser: (blockuser) => {
    let url = 'api/auth/user-management/block-user'
    return request2.callApi(url, 1, blockuser, 'PUT', null).then(res => {
      if (res.status === 200) {
        // api.showMessage('Khôi phục mật khẩu thành công !', 'Thông báo')
        return res

      }
      else {
        api.showMessage('Hệ thông đang gặp lỗi ! Vui lòng thử lại trong vài phút !', 'Thông báo')
      }
    })
  },

  actAddUserRequest: (user) => {
    let url = 'api/auth/user-management/create-user'
    return request2.callApi(url, 4, user, 'POST', null).then(res => {
      if (res.status === 200) {
        return api.actAddUser(res.data)
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },
  actUpdateUSerRequest: (user) => {

    let url = `api/user/${user.id}`
    return request2.callApi(url, 5, user, 'PATCH').then(res => {
      if (res.status === 200) {

        return api.actUpdateUser(res.data)
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actFetchStoreUsersRequest: (skip, limit, select, where, sort) => {
    let url = 'api/storeUser'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'userId,storeId';

    return request2.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFetchStoreUsers(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchStoreAreaRequest: (skip, limit, select, where) => {
    let url = 'api/storeArea'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    // url += '&populate=' + 'storeAreaId';

    return request2.callApi(url, 1, null, 'GET', null).then(res => {

      if (res.status === 200) {
        api.actFetchStoreAreas(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Lỗi hệ thống ! Quý khách vui lòng chờ ít phút')
      }

    })
  },

  actDeleteStoreUserRequest: (id) => {
    let url = `api/storeUser/${id}`
    return request2.callApi(url, 4, null, 'DELETE').then(res => {
      if (res.status === 200) {
        return api.actDeleteStoreUser(id);
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actAddStoreUserRequest: (storeuser) => {
    let url = 'api/store-user-management/add-user-to-store'
    return request2.callApi(url, 4, storeuser, 'POST', null).then(res => {

      if (res.status === 200) {
        return res.data
      }
      // console.log(res.statusText)
      // this.props.history.push('#/400')
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actSendSMSRequest: (data) => {
    let url = 'api/push-notice-management/send-sms'
    return request2.callApi(url, 3, data, 'POST', null).then(res => {
      if (res.status === 200) {
        return res
      }
      // console.log(res.statusText)
      // this.props.history.push('#/400')
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },


  actFetchUsersRequest: (skip, limit, select, where, sort) => {
    let url = 'api/user'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'avatar';

    return request2.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFetchUsers(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Quý khách thông cảm !', 'Thông báo')
      }
    })
  },

  actGetRole: (skip, limit, select, where) => {
    let url = 'api/role'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }

    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }

    return request2.callApi(url, 1, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },
  actUnBlockUser: (unblockuser) => {
    let url = 'api/auth/user-management/unblock-user'
    return request2.callApi(url, 1, unblockuser, 'PUT', null).then(res => {
      if (res.status === 200) {
        // api.showMessage('Khôi phục mật khẩu thành công !', 'Thông báo')
        return res

      }

      api.showMessage('Hệ thông đang gặp lỗi ! Vui lòng thử lại trong vài phút !', 'Thông báo')
    })
  },

  actBlockUser: (blockuser) => {
    let url = 'api/auth/user-management/block-user'
    return request2.callApi(url, 1, blockuser, 'PUT', null).then(res => {
      if (res.status === 200) {
        // api.showMessage('Khôi phục mật khẩu thành công !', 'Thông báo')
        return res

      }
      else {
        api.showMessage('Hệ thông đang gặp lỗi ! Vui lòng thử lại trong vài phút !', 'Thông báo')
      }
    })
  },

  actAddUserRequest: (user) => {
    let url = 'api/auth/user-management/create-user'
    return request2.callApi(url, 4, user, 'POST', null).then(res => {
      if (res.status === 200) {
        return api.actAddUser(res.data)
      }
      // console.log(res.statusText)

      api.showMessage(res.data.message)
    })
  },
  actUpdateUSerRequest: (user) => {

    let url = `api/user/${user.id}`
    return request2.callApi(url, 5, user, 'PATCH').then(res => {
      if (res.status === 200) {

        return api.actUpdateUser(res.data)
      }
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actFetchStoreUsersRequest: (skip, limit, select, where, sort) => {
    let url = 'api/storeUser'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    if (sort) {
      url += '&sort=' + JSON.stringify(sort);
    }
    url += '&populate=' + 'userId,storeId';

    return request2.callApi(url, 4, null, 'GET', null).then(res => {
      if (res.status === 200) {
        api.actFetchStoreUsers(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },

  actFetchStoreAreaRequest: (skip, limit, select, where) => {
    let url = 'api/storeArea'

    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (select) {
      url += '&select=' + (select);
    }
    if (where) {
      url += '&where=' + JSON.stringify(where);
    }
    // url += '&populate=' + 'storeAreaId';

    return request2.callApi(url, 1, null, 'GET', null).then(res => {

      if (res.status === 200) {
        api.actFetchStoreAreas(res.data.data)
        return res.data
      }
      else {
        api.showMessage('Lỗi hệ thống ! Quý khách vui lòng chờ ít phút')
      }

    })
  },

  actDeleteStoreUserRequest: (id) => {
    let url = `api/storeUser/${id}`
    return request2.callApi(url, 4, null, 'DELETE').then(res => {
      if (res.status === 200) {
        return api.actDeleteStoreUser(id);
      }
      // console.log(res.statusText)
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },

  actAddStoreUserRequest: (storeuser) => {
    let url = 'api/store-user-management/add-user-to-store'
    return request2.callApi(url, 4, storeuser, 'POST', null).then(res => {
      console.log('ttt', res)
      if (res.status === 200) {
        return res.data
      }
      // console.log(res.statusText)
      // this.props.history.push('#/400')
      api.showMessage("Lỗi hệ thông ! Quý khách vui lòng quay lại trang chủ để chúng tôi lấy lại thông tin !")
    })
  },
  actGetCustomerUserServiceExportExcell: (cusName, cusPhone, storeName, storeId, fromDate, toDate, token) => {
    let url = 'api/report/customer/customer-using-services?exportExcel=1'
    let where = {};
    if (cusName) {
      where.customerName = { contains: cusName };
    }

    if (cusPhone) {
      where.customerPhone = { contains: cusPhone };
    }

    if (storeId) {
      where.storeId = storeId;
    }
    if (fromDate) {
      url += '&fromDate=' + moment(fromDate).startOf('days').valueOf()
    } else {
      url += '&fromDate=' + (moment().startOf('month').valueOf());
    }
    if (toDate) {
      url += '&toDate=' + moment(toDate).endOf('days').valueOf();
    } else {
      url += '&toDate=' + (moment().endOf('month').valueOf());
    }
    if (storeName) {
      where.storeName = storeName;
    }
    // if (storeId && storeId !== 'undefined') {
    //   url += '&storeId=' + (storeId);
    // }
    url += '&where=' + JSON.stringify(where);
    return request2.report(url, 5, token);
  },
  actGetCustomerUserService: (skip, limit, cusName, cusPhone, storeName, storeId, fromDate, toDate) => {
    let url = 'api/report/customer/customer-using-services'
    let where = {};
    if (cusName) {
      where.customerName = { contains: cusName };
    }

    if (cusPhone) {
      where.customerPhone = { contains: cusPhone };
    }

    if (storeId) {
      where.storeId = storeId;
    }
    if (storeName) {
      where.storeName = { contains: storeName };
    }
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
    else {
      url += '?'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    url += '&where=' + JSON.stringify(where);
    if (fromDate) {
      url += '&fromDate=' + moment(fromDate).startOf('day').valueOf()
    } else {
      url += '&fromDate=' + (moment().startOf('month'));
    }
    if (toDate) {
      url += '&toDate=' + moment(toDate).endOf('day').valueOf();
    } else {
      url += '&toDate=' + (moment().endOf('month'));
    }
    return request2.callApi(url, 5, null, 'GET', null).then(res => {
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })
  },
  sendEmail(email, content, title, token, apiVersion) {
    let url = 'api/push-notice-management/send-email'
    let data = {
      email,
      content,
      title

    }
    return request.posts(url, data, token, apiVersion)
  },
  getBillHistoryChangeState(where, populate, token, apiVersion) {
    let url = 'api/billChangeStageLog'
    let data = {
      where,
      populate
    }
    return request.get(url, data, token, apiVersion)

  },
  getStoreStar(storeId, from, to, token, apiVersion) {
    let url = 'api/report/rating/count-rating-by-star'
    let data = {
      storeId,
      from,
      to
    }
    return request.get(url, data, token, apiVersion)
  },
  getStoreRatting(storeId, from, to, token, apiVersion) {
    let url = 'api/report/rating/count-rating-by-type'
    let data = {
      storeId,
      from,
      to
    }
    return request.get(url, data, token, apiVersion)
  },
  getSMShistory(sort, where, populate, token, apiVersion) {
    let url = 'api/logsmsemail'
    let data = {
      sort,
      where,
      populate,

    }
    return request.get(url, data, token, apiVersion)
  },
  getBillInfo(barcode, billId, apiVersion, token) {
    let url = 'api/bill-management/customer-get-bill-info'
    let data = ''
    let newUrl = url + '?barcode=' + barcode + '&billId=' + billId
    return request.posts(newUrl, data, token, apiVersion)

  },
  getHistoryVoucherPartner(customerId, token, apiVersion) {
    let url = 'api/voucher-partner/history'
    let data = {
      customerId
    }
    return request.posts(url, data, token, apiVersion)
  },
  actGetRevenueStructure: (skip, limit, to, froms,storeId) => {
    try{
    let url = 'api/report/bill/detail-report'
  
    if (skip) {
      url += '?skip=' + (skip) + '&';
    }
   
    else {
      url += '?skip=0&'
    }
    if (limit) {
      url += 'limit=' + (limit);
    }
    if (froms) {
      url += '&startDate=' + moment(froms).startOf('days').valueOf()
    } else {
      url += '&startDate=' + (moment().startOf('month'));
    }
    if (to) {
      url += '&endDate=' + moment(to).endOf('days').valueOf();
    } else {
      url += '&endDate=' + (moment().endOf('month'));
    }
    url+='&storeId='+(storeId)
    return request2.callApi(url, 3, null, 'GET', null).then(res => {
      console.log(res,'888')
      if (res.status === 200) {
        return res
      }
      else {
        api.showMessage('Hệ thống gặp chút vấn đề ! Wating...', 'Thông báo')
      }
    })}
    catch(err){
      console.log(err)
    }
  },
  actFetchRevenueStructureExportExcel: (from, to, storeId, sort) => {
    let url = 'api/report/bill/detail-report?exportExcel=true&skip=0&limit=10000000000'
    if (from) {
      url += '&startDate=' + moment(from).startOf('day').valueOf()
    } else {
      url += '&startDate=' + (moment().startOf('month'));
    }
    if (to) {
      url += '&endDate=' + moment(to).endOf('day').valueOf();
    } else {
      url += '&endDate=' + (moment().endOf('month'));
    }
    if (storeId) {
      url += '&storeId=' + storeId;
    }
    return request2.report(url, 5);
  },
}
export default dataService
