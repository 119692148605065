import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500, Register } from './views/Pages';
import LoginStaff from './views/Pages/Login/staffLogin';
import ChangePassword from './views/Pages/ChangePassword/ChangePassword'
import UI from './UI/index.js';
import PreviewPrintf from './views/Customer/CustomerService/Print';
import { connect } from './services/connect'
import BillInfoForCustomer from './views/Customer/BillData/BillInfo'
// import { renderRoutes } from 'react-router-config';
// let store = configStore()

class App extends Component {
  constructor(props) {
    super(props);
    connect(message => {
      console.log('aaa', message);
    });
  }
  render() {
    return (
      <React.Fragment>
        <UI />
        <HashRouter>
          <Switch>
            {/* login */}
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path='/loginstaff' name=" Staff Login" component={LoginStaff} />
            {/* <Route exact path='/change-password' name="Đổi mật khẩu" component={ChangePassword} /> */}
            {/*  */}
            {/* customer */}
            {/* <Route exact path='/customer/search' name=" Khach hang" component={Customer} /> */}
            {/*  */}
            <Route exact path='/bill-info' component={BillInfoForCustomer} />
            <Route exact path='/previewPrint/:id' component={PreviewPrintf} />
            <Route exact path="/register" name="Register Page" component={Register} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
      </React.Fragment>

    );
  }
}

export default App;
