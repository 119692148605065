import config from '../services/config'
import api from '../services/index'
const request = {
  get: (url, data, token, apiVerson) => {
    let passingValue = (data ? '?' : '') + (data.storeId?'storeId='+data.storeId+'&':'')+( data.from?'from='+data.from+'&':'')+(data.to?'to='+data.to:'') +(data.customerId ? 'customerId=' + data.customerId : '') +(data.storeInfo? '&storeInfo=' +data.storeInfo : '') + (data.skip ? 'skip=' + data.skip : '') + (data.populate ? '&populate=' + data.populate : '') + (data.select ? '&select=' + data.select : '') + (data.limit ? '&limit=' + data.limit : '') + (data.sort ? '&sort=' + JSON.stringify(data.sort) : '') + (data.where ? '&where=' + JSON.stringify(data.where) : '')

    if ((url + '').indexOf('http') == -1) url = `${process.env.REACT_APP_HOST || config.HOST}` + '/' + url + passingValue
    console.log('getRequest', url)
    let status = null
    return fetch(url, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': (token ? token : localStorage.getItem('userTK')),
        'Api-Version': (apiVerson ? apiVerson : 3),
        // 'api-version': 'public'
      },
    }).then(function (response) {
      status = response.status
      return response.json()
    }, function (error) {
      console.log('errorGet', error)
    }).then(function (data) { return { status, data } }, function (err) {
      console.log(err)
    })
  },
  posts: (url, data, token, apiVerson) => {

    if ((url + '').indexOf('http') == -1) url = `${process.env.REACT_APP_HOST || config.HOST}` + '/' + url
    console.log('post', apiVerson)
    let status = null
    if(data !=''){
    return fetch(url, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': (token ? token : localStorage.getItem('userTK')),
        'Api-Version': (apiVerson ? apiVerson : 3),
        // 'api-version': 'public'
      },
      body: JSON.stringify(data)
    }).then(function (response) {
      status = response.status
      console.log('RESPONSE', response)
      return response.json()
    }, function (error) {
      console.log('errorGet', error)
    }).then(function (data) { return { status, data } }, function (err) {
      console.log(err)
    })
    }else{
      return fetch(url, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': (token ? token : localStorage.getItem('userTK')),
          'Api-Version': (apiVerson ? apiVerson : 3)
        },
      }).then(function (response) {
        status = response.status
        console.log('RESPONSE', response)
        return response.json()
      }, function (error) {
        console.log('errorGet', error)
      }).then(function (data) { return { status, data } }, function (err) {
        console.log(err)
      })
    }
  },
  patch: (url, data, token, apiVerson) => {
    if ((url + '').indexOf('http') == -1) url = `${process.env.REACT_APP_HOST || config.HOST}` + '/' + url
    console.log('post', url, '\n data patch', data)
    let status = null
    return fetch(url, {
      method: "PATCH",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': (token ? token : localStorage.getItem('userTK')),
        'Api-Version': (apiVerson ? apiVerson : 3)
      },
      body: JSON.stringify(data)
    }).then(function (response) {
      status = response.status
      console.log('RESPONSE', response)
      return response.json()
    }, function (error) {
      console.log('errorGet', error)
    }).then(function (data) { return { status, data } }, function (err) {
      console.log(err)
    })
  },
  put: (url, data, token, apiVerson) => {
    if ((url + '').indexOf('http') == -1) url = `${process.env.REACT_APP_HOST || config.HOST}` + '/' + url
    console.log('post', url, '\n data patch', data)
    let status = null
    return fetch(url, {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token ? token : localStorage.getItem('userTK'),
        'Api-Version': (apiVerson ? apiVerson : 3)
      },
      body: JSON.stringify(data)
    }).then(function (response) {
      status = response.status
      console.log('RESPONSE', response)
      return response.json()
    }, function (error) {
      console.log('errorGet', error)
    }).then(function (data) { return { status, data } }, function (err) {
      console.log(err)
    })
  },
  delete: (url, token, apiVerson) => {
    if ((url + '').indexOf('http') == -1) url = `${process.env.REACT_APP_HOST || config.HOST}` + '/' + url
    console.log('getRequest', url)
    let status = null
    return fetch(url, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': (token ? token : localStorage.getItem('userTK')),
        'Api-Version': (apiVerson ? apiVerson : 3)
      },
    }).then(function (response) {
      status = response.status
      return response.json()
    }, function (error) {
      console.log('errorGet', error)
    }).then(function (data) { return { status, data } }, function (err) {
      console.log(err)
    })
  },
  // post: (url, data, token,apiVerson) => {
  //     if ((url + '').indexOf('http') == -1) url = config.HOST + '/' + url
  //     return new Promise(function (resolve, reject) {
  //         let options = {
  //             method: 'POST',
  //             headers: {
  //                 'Accept': 'application/json',
  //                 'Content-Type': 'application/json',
  //                 'Authorization': (token?token:localStorage.getItem('stTK')),
  //                 'Api-Version':(apiVerson)
  //             },
  //             body: JSON.stringify(data)
  //         };
  //         console.log('check',localStorage.getItem('stTK'),token)
  //         console.log('header',options)
  //         console.log('POST',url)

  //          fetch(url, options).then((response) => {
  //             try {
  //                 let data= {
  //                     status: response.status,
  //                     data: response.json()
  //                 }
  //                 return  data
  //             } catch (err) {
  //                 reject({ err: 2, msg: 'Phiên làm việc hết hạn' });
  //             }
  //         }).then((rs) => {
  //             console.log('Response',rs)
  //             if (rs.err == 404) {
  //                 api.showMessage('Phiên làm việc hết hạn !')

  //                 return reject({ err: 404, msg: 'Phiên làm việc hết hạn' })

  //             }
  //             // if (rs.err == 2) {
  //             //     api.showMessage('Phiên làm việc hết hạn !')
  //             //     api.hideLoading();
  //             //     return reject({ err: 2, msg: 'Phiên làm việc hết hạn' })

  //             // }
  //             resolve(rs);
  //             // try{
  //             //     resolve(JSON.parse(data, (key, value) => {
  //             //         console.log(`[POST]${url}`,value);
  //             //         return value && value.type === 'Buffer'
  //             //             ? Buffer.from(value.data)
  //             //             : value;
  //             //     }));
  //             // }catch(err){
  //             //     console.log('parse json err', err, data);
  //             // }

  //         }).catch((err) => {
  //         })
  //     });

  // },



}

export default request
