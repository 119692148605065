const url = window && window.location && window.location.hostname;

// let hostName = 'http://giatkytest.mpoint.vn'
let hostName = 'https://likewash-backend.metapass.vn'
// let hostName = 'http://localhost:1337'
// let hostName = 'http://192.168.1.12:1337'

// if (url == 'localhost') {
//   hostName = 'http://localhost:1337'
//   // hostName = 'http://giatky.quangkhang.club'
//   // }else{
//   //     hostName = 'https://admin.mpoint.vn'
// }

const config = {
  HOST: hostName,

}
export default config;
