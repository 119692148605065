import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import api from '../services/index';
class MessageBox extends Component {
    render() {
        return (
            <div className="static-modal">
                <Modal isOpen={true} >
                    <ModalHeader>
                     {this.props.title ? this.props.title :'Cảnh báo'}
                    </ModalHeader>

                    <ModalBody>{this.props.message}</ModalBody>

                    <ModalFooter>
                        <Button onClick={() => { api.hideMessage() }} >Đóng</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default MessageBox
