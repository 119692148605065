let store = null
let host = null
const api = {

  setStore: (newstore) => {
    store = newstore
  },

  actFetchUsers: (user) => {
    store.dispatch({ type: 'FETCH_USERS', user })
  },
  actAddUser: (user) => {
    store.dispatch({ type: 'ADD_USER', user })
  },
  actUpdateUser: (user) => {
    // console.log(user)
    store.dispatch({ type: 'UPDATE_USER', user })
  },

  actDeleteUser: (id) => {
    store.dispatch({ type: 'DELETE_USER', id })
  },
  actFetchStoreUsers: (storeuser) => {
    store.dispatch({ type: 'FETCH_STOREUSERS', storeuser })
  },
  actAddStoreUser: (storeuser) => {
    store.dispatch({ type: 'ADD_STOREUSER', storeuser })
  },
  actDeleteStoreUser: (id) => {
    store.dispatch({ type: 'DELETE_STOREUSER', id })
  },

  actFetchStoreAreas: (storearea) => {
    store.dispatch({ type: 'FETCH_STOREAREAS', storearea })
  },
  actAddStoreArea: (storearea) => {
    store.dispatch({ type: 'ADD_STOREAREA', storearea })
  },
  actUpdateStoreArea: (storearea) => {
    // console.log(store)
    store.dispatch({ type: 'UPDATE_STOREAREA', storearea })
  },

  actDeleteStoreArea: (id) => {
    store.dispatch({ type: 'DELETE_STOREAREA', id })
  },
  actGetStoreArea: (storearea) => {
    store.dispatch({ type: 'EDIT_STOREAREA', storearea })
  },
  actFetchVouchers: (voucher) => {
    store.dispatch({ type: 'FETCH_VOUCHERS', voucher })
  },
  actAddVoucher: (voucher) => {
    store.dispatch({ type: 'ADD_VOUCHER', voucher })
  },
  actUpdateVoucher: (voucher) => {
    // console.log(store)
    store.dispatch({ type: 'UPDATE_VOUCHER', voucher })
  },
  actFetchVouchersCleanID: (voucherCleanId) => {
    store.dispatch({ type: 'FETCH_VOUCHERS_CLEANID', voucherCleanId })
  },
  actAddVoucherCleanId: (voucherCleanId) => {
    store.dispatch({ type: 'ADD_VOUCHER_CleanID', voucherCleanId })
  },
  actUpdateVoucherCleanID: (voucher) => {
    // console.log(store)
    store.dispatch({ type: 'UPDATE_VOUCHER_CleanId', voucher })
  },
  actGetVoucher: (voucher) => {
    store.dispatch({ type: 'EDIT_VOUCHER', voucher })
  },

  setUserToken: (token) => {
    store.dispatch({ type: "SET_USER_TOKEN", token })
  },
  setStoreToken: (token) => {
    store.dispatch({ type: "SET_STORE_TOKEN", token })
  },
  setUserInfo: (user) => {
    store.dispatch({ type: "SET_USER_INFO", data: user })
  },
  showMessage: (message, titleMessage) => {
    store.dispatch({ type: "SHOW_MESSAGE", message, titleMessage })
  },
  hideMessage: () => {
    store.dispatch({ type: "HIDE_MESSAGE" })
  },
  showLoading: () => {
    store.dispatch({ type: "SHOW_LOADING" })
  },
  hideLoading: () => {
    store.dispatch({ type: "HIDE_LOADING" })
  },
  showConfirm: (title, body, bodyConfirmOK, bodyConfirmNO, titleBtnOK, titleBtnNO) => {
    store.dispatch({ type: "SHOW_CONFIRMBOX", title, body, bodyConfirmOK, bodyConfirmNO, titleBtnOK, titleBtnNO })
  },
  hideCofirm: () => {
    store.dispatch({ type: "HIDE_CONFIRMBOX" })
  },
  actFetchNotifies: (notify) => {
    store.dispatch({ type: 'FETCH_NOTIFIES', notify })
  },

  getToken: () => { return (store ? store.getState().userState.token.token : '') },
  getLang() {
    // if (!store.getState()) return 'en'
    // return store.getState().langState.lang
    return localStorage.getItem('lang') ? localStorage.getItem('lang') : 'vi'
  },
  getHost() {
    return host
  },
  setCate: (category) => {
    store.dispatch({ type: "SET_CATE", category })
  },
  setLang: (lang) => { store.dispatch({ type: "SET_LANG", lang }) },
  genUrl: (img) => {
    return process.env.PUBLIC_URL + img
  }


}
export default api;
