let roleId
let items

if (JSON.parse(localStorage.getItem('userinfo'))) {
  roleId = JSON.parse(localStorage.getItem('userinfo')).roleId
  if (roleId.includes(4)) {
    items = {
      items: [
        {
          name: 'Khách hàng',
          url: '/customer',
          icon: 'icon-bell',
          children: [
            {
              name: 'Bán hàng',
              url: '/customer/search',

            },

          ],
          roles: [3]
        },
        {
          divider: true,
          roles: ['*']

        },
        {
          name: 'Quản lí nhân viên',
          url: '/users',
          icon: 'icon-bell',
          roles: [3],
          children: [
            {
              name: 'Quản lý người dùng',
              url: '/users/user',
            },
            {
              name: 'Nhân viên cửa hàng',
              url: '/users/storeUser'
            }
          ]
        },
        {
          divider: true,
          roles: ['*']

        },
        {
          title: true,
          name: 'Extras',
          roles: ['*']

        },
        {
          name: 'Hóa đơn',
          url: '/bill',
          icon: 'cui-layers',
          children: [
            {
              name: 'Hóa đơn mới nhận',
              url: '/bill/newbill',
            },
            {
              name: 'Hóa đơn đang xử lý',
              url: '/bill/process-bill',
            },
            {
              name: 'Hóa đơn chuyển xưởng',
              url: '/bill/workshop-bill',
            },
            {
              name: 'Hóa đơn đã hoàn thành ',
              url: '/bill/finish-bill',
            },
            {
              name: 'Hóa đơn cần trả',
              url: '/bill/need-pay-bill',
            },
            {
              name: 'Hóa đơn đã trả',
              url: '/bill/payed-bill',
            },
            {
              name: 'Hóa đơn nợ',
              url: '/bill/debt-bill',
            },
            {
              name: 'Danh sách hóa đơn',
              url: '/bill/all-bill',
            },
          ],
          role: ['*']
        },
        {
          name: 'Quản lý cửa hàng',
          url: '/quanlycuahang',
          icon: 'icon-basket-loaded',
          children: [
            {
              name: 'Cập nhật cửa hàng',
              url: '/quanlycuahang/stores',
            },
            {
              name: 'Đổi mật khẩu',
              url: '/password/changepasswordstore',
            },
            {
              name: 'Cập nhật giá sản phẩm',
              url: '/quanlycuahang/updateprice'
            },
            {
              name: 'Quản lý voucher',
              url: '/quanlyvoucher/listVoucher'
            },
            {
              name: 'Quản lý thẻ trả trước',
              url: '/quanlyvoucherCleanId/listVoucherCleanId'
            }

          ],
          role: ['*']
        },

        {
          name: 'Thông báo',
          url: '/notice',
          icon: 'icon-basket-loaded',
          children: [
            {
              name: 'Tin nhắn',
              url: '/notice/notifies',

            },
            {
              name: 'Tin nhắn hệ thống',
              url: '/notice/system',
              // icon: 'cui-pencil',
            },

          ]
        },
        {
          name: 'Báo cáo',
          url: '/report',
          icon: 'cui-graph',
          children: [
            // {
            //   name: 'Báo cáo chi phí và doanh thu',
            //   url: '/report/costs-and-revenue',
            //   icon: 'icon-bell',
            // },
            {
              name: 'Danh sách nợ',
              url: '/debt',
              icon: 'icon-bell',
            },
            {
              name: 'Thống kê Email',
              url: '/report/email',
              icon: 'icon-bell',
            },
            {
              name: 'Thống kê SMS',
              url: '/report/sms',
              icon: 'icon-bell',
            },
            {
              name: 'Hiệu suất nhân viên',
              url: '/report/finish-work',
              icon: 'icon-bell',
            },
            {
              name: 'Doanh số nhân viên',
              url: '/report/turnover',
              icon: 'icon-directions',
            },
            {
              name: 'Doanh số chuyển xưởng',
              url: '/report/partner-store/turnover',
              icon: 'cui-bolt',
            },
            {
              name: 'Doanh số cửa hàng',
              url: '/report/store/turnover',
              icon: 'cui-bolt',
            },
            {
              name: 'Danh sách tăng ca',
              url: '/report/overtime',
              icon: 'cui-briefcase',
            },
            {
              name: 'Đánh giá người dùng',
              url: '/report/rating',
              icon: 'cui-briefcase',
            },
            {
              name: 'Tổng kết số lần đánh giá',
              url: '/report/store/star',
              icon: 'cui-briefcase',
            },
            {
              name: 'Tổng kết số lần nhận xét theo từng hạng mục',
              url: '/report/store/ratting',
              icon: 'cui-briefcase',
            },
            {
              name: 'Thống kê khách hàng sử dụng dịch vụ',
              url: '/report/CustomerUseService',
              icon: 'icon-bell',
            },
            {
              name: ' Danh sách nợ theo từng ngày',
              icon: 'icon-bell',
              url: '/report/debt-bill-ispay-inday',
            },

          ],
          roles: [4]

        },


      ],
    };
  }
  else if (roleId.includes(7)) {
    items = {
      items: [
        {
          name: 'Khách hàng',
          url: '/customer',
          icon: 'icon-bell',
          children: [
            {
              name: 'Bán hàng',
              url: '/customer/search',

            },

          ],
          roles: [3]
        },
        {
          divider: true,
          roles: ['*']

        },
        // {
        //   name: 'Quản lí nhân viên test',
        //   url: '/usermanagerment/user',
        //   icon: 'icon-bell',
        //   roles: [3],
        //   children:[
        //     {
        //       name:'Người dùng',
        //       url:'/usermanagerment/user',
        //     },
        //     {
        //       name:'Nhân viên cửa hàng',
        //       url:'usermanagerment/storeUser'
        //     }
        //   ]
        // },
        // {
        //   name: 'Quản lí nhân viên test',
        //   url: '/usermanagerment/user',
        //   icon: 'icon-bell',
        //   roles: [3],
        //   children:[
        //     {
        //       name:'Quản lý người dùng',
        //       url:'/users/user',
        //     },
        //     {
        //       name:'Nhân viên cửa hàng',
        //       url:'/users/storeUser'
        //     }
        //   ]
        // },
        {
          name: 'Quản lí nhân viên',
          url: '/users',
          icon: 'icon-bell',
          roles: [3],
          children: [
            {
              name: 'Quản lý người dùng',
              url: '/users/user',
            },
            {
              name: 'Nhân viên cửa hàng',
              url: '/users/storeUser'
            }
          ]
        },
        {
          divider: true,
          roles: ['*']

        },
        {
          title: true,
          name: 'Extras',
          roles: ['*']

        },
        {
          name: 'Hóa đơn',
          url: '/bill',
          icon: 'cui-layers',
          children: [
            {
              name: 'Hóa đơn mới nhận',
              url: '/bill/newbill',
            },
            {
              name: 'Hóa đơn đang xử lý',
              url: '/bill/process-bill',
            },
            {
              name: 'Hóa đơn chuyển xưởng',
              url: '/bill/workshop-bill',
            },
            {
              name: 'Hóa đơn đã hoàn thành ',
              url: '/bill/finish-bill',
            },
            {
              name: 'Hóa đơn cần trả',
              url: '/bill/need-pay-bill',
            },
            {
              name: 'Hóa đơn đã trả ',
              url: '/bill/payed-bill',
            },
            {
              name: 'Hóa đơn nợ',
              url: '/bill/debt-bill',
            },
            {
              name: 'Danh sách hóa đơn',
              url: '/bill/all-bill',
            },
          ],
          role: ['*']
        },
        {
          name: 'Quản lý cửa hàng',
          url: '/quanlycuahang',
          icon: 'icon-basket-loaded',
          children: [
            {
              name: 'Cập nhật cửa hàng',
              url: '/quanlycuahang/stores',
            },
            {
              name: 'Đổi mật khẩu',
              url: '/password/changepasswordstore',
            },
            {
              name: 'Cập nhật giá sản phẩm',
              url: '/quanlycuahang/updateprice'
            },
            {
              name: 'Quản lý voucher',
              url: '/quanlyvoucher/listVoucher'
            },
            {
              name: 'Quản lý thẻ trả trước',
              url: '/quanlyvoucherCleanId/listVoucherCleanId'
            }

          ],
          role: ['*']
        },

        {
          name: 'Thông báo',
          url: '/notice',
          icon: 'icon-basket-loaded',
          children: [
            {
              name: 'Tin nhắn',
              url: '/notice/notifies',

            },
            {
              name: 'Tin nhắn hệ thống',
              url: '/notice/system',
              // icon: 'cui-pencil',
            },

          ]
        },
        {
          name: 'Báo cáo',
          url: '/report',
          icon: 'cui-graph',
          children: [
            {
              name: 'Danh sách nợ',
              url: '/debt',
              icon: 'icon-bell',
            },
            {
              name: 'Thống kê Email',
              url: '/report/email',
              icon: 'icon-bell',
            },
            {
              name: 'Thống kê SMS',
              url: '/report/sms',
              icon: 'icon-bell',
            },
            {
              name: 'Hiệu suất nhân viên',
              url: '/report/finish-work',
              icon: 'icon-bell',
            },
            {
              name: 'Doanh số nhân viên',
              url: '/report/turnover',
              icon: 'icon-directions',
            },
            {
              name: 'Doanh số chuyển xưởng',
              url: '/report/partner-store/turnover',
              icon: 'cui-bolt',
            },
            {
              name: 'Doanh số cửa hàng',
              url: '/report/store/turnover',
              icon: 'cui-bolt',
            },
            {
              name: 'Danh sách tăng ca',
              url: '/report/overtime',
              icon: 'cui-briefcase',
            },
            {
              name: 'Đánh giá người dùng',
              url: '/report/rating',
              icon: 'cui-briefcase',
            },
            {
              name: 'Tổng kết số lần đánh giá',
              url: '/report/store/star',
              icon: 'cui-briefcase',
            },
            {
              name: 'Tổng kết số lần nhận xét theo từng hạng mục',
              url: '/report/store/ratting',
              icon: 'cui-briefcase',
            },
            {
              name: 'Thống kê khách hàng sử dụng dịch vụ',
              url: '/report/CustomerUseService',
              icon: 'icon-bell',
            },
            {
              name: 'Danh sách nợ theo từng ngày',
              url: '/report/debt-bill-ispay-inday',
            },
          ],
          roles: [4]

        },


      ],
    };
  }
  else {
    items = {
      items: [
        {
          name: 'Khách hàng',
          url: '/customer',
          icon: 'icon-bell',
          children: [
            {
              name: 'Bán hàng',
              url: '/customer/search',
            },
          ],
          roles: [3]
        },

        {
          name: 'Hóa đơn',
          url: '/bill',
          icon: 'cui-layers',
          children: [
            {
              name: 'Hóa đơn mới nhận',
              url: '/bill/newbill',
            },
            {
              name: 'Hóa đơn đang xử lý',
              url: '/bill/process-bill',
            },
            {
              name: 'Hóa đơn chuyển xưởng',
              url: '/bill/workshop-bill',
            },
            {
              name: 'Hóa đơn đã hoàn thành ',
              url: '/bill/finish-bill',
            },
            {
              name: 'Hóa đơn đã trả ',
              url: '/bill/payed-bill',
            },
            {
              name: 'Hóa đơn cần trả',
              url: '/bill/need-pay-bill',
            },
            {
              name: 'Hóa đơn nợ',
              url: '/bill/debt-bill',
            },
            {
              name: 'Danh sách hóa đơn',
              url: '/bill/all-bill',
            },
          ],
          role: ['*']
        },
        {
          name: 'Thông báo',
          url: '/notice',
          icon: 'icon-basket-loaded',
          children: [
            {
              name: 'Tin nhắn',
              url: '/notice/notifies',

            },
            {
              name: 'Tin nhắn hệ thống',
              url: '/notice/system',
              // icon: 'cui-pencil',
            },

          ]
        },
        {
          name: 'Báo cáo',
          url: '/notice',
          icon: 'icon-basket-loaded',
          children: [
            {
              name: 'Danh sách nợ',
              url: '/debt',
              icon: 'icon-bell',
            },
            {
              name: 'Doanh số cửa hàng',
              url: '/report/store/turnover',
              icon: 'cui-bolt',
            },
            {
              name: 'Doanh số nhân viên',
              url: '/report/turnover',
              icon: 'icon-directions',
            },
            {
              name: ' Danh sách nợ theo từng ngày',
              icon: 'icon-bell',
              url: '/report/debt-bill-ispay-inday',
            },
            {
              name: "Báo cáo doanh thu theo sản phẩm",
              icon: "icon-bell",
              url: '/report/revenue-structure'
            }
          ]
        },


        // {   name: 'Đánh giá người dùng',
        // url: '/report/rating',
        // icon: 'cui-briefcase',
        // },
      ]
    }
  }
}
export default items;

