import React, { Component } from 'react';
import { Button, Form, Card, CardBody, CardFooter, Container, FormGroup, InputGroupAddon, Col, InputGroup, Input, ModalFooter, InputGroupText, Row } from 'reactstrap';
import dataservice from '../../../services/dataservice';
import api from '../../../services/index'
class ChangePassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      passwordold: '',
      passwordnews: '',
      passwordnew: '',
      id: '',
      passwordoldError: '',
      passwordnewsError: '',
      passwordnewError: '',
    };


  }
  validate = () => {
    let passwordoldError = "";
    let passwordnewsError = "";
    let passwordnewError = "";
    var { passwordold, passwordnews, passwordnew } = this.state;
    if (!passwordnews) {
      passwordnewsError = 'Trường này không được để trống !'
    }
    if (!passwordold) {
      passwordoldError = 'Mật khẩu hiện tại này không được để trống  !'
    }
    if (!passwordnew) {
      passwordnewError = 'Mật khẩu mới không được để trống  !'
    }

    if (passwordoldError || passwordnewsError || passwordnewError) {
      this.setState({ passwordoldError, passwordnewsError, passwordnewError });
      return false;
    }
    return true
  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    let name=JSON.parse(localStorage.getItem('userinfo')).account
    var { username, passwordold, passwordnews, passwordnew, id } = this.state;
    const isValid = this.validate()
    if (isValid) {
      var password = {
        account: name,
        oldPassword: passwordold,
        newPassword: passwordnew,
      }
      // api.dataService.actUpdateRoomRequest(rooms)
      // if (id) {
      //     api.dataService.actUpdateRoomRequest(rooms)
      // }
      // else {
      //     api.dataService.actAddRoomRequest(rooms)
      // }
      if (passwordnews === passwordnew) {
        await dataservice.actChangePassword(password)
      }
      else {
        api.api.showMessage('Mật khẩu không trùng khớp ! ', 'Thông báo')
      }
      this.setState({ passwordnewError: '', passwordnewsError: '', passwordoldError: '' })
    }
    // api.dataService.actFetchRoomsRequest();
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  render() {
    let name=JSON.parse(localStorage.getItem('userinfo')).account
    var { passwordnewError, passwordnewsError, passwordoldError } = this.state
    return (
      <Container>
        <Row className="justify-content-center animated fadeIn">
          <Col md="6">
            <Card className="mx-4">
              <CardBody className="p-4">
                <Form onSubmit={this.onSubmit}>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Username</InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" id="username" name="username" value={name} disabled  />
                      <InputGroupAddon addonType="append">
                        <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Mật khẩu hiện tại</InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" id="passwordold" name="passwordold" onChange={this.onChange.bind(this)} />
                      <InputGroupAddon addonType="append">
                        <InputGroupText><i className="fa fa-envelope"></i></InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {passwordoldError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{passwordoldError}</i> </p> : ''}
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Mật khẩu mới</InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" id="passwordnew" name="passwordnew" onChange={this.onChange.bind(this)} />
                      <InputGroupAddon addonType="append">
                        <InputGroupText><i className="fa fa-asterisk"></i></InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {passwordnewError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{passwordnewError}</i> </p> : ''}
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Nhập lại mật khẩu mới</InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" id="passwordnews" name="passwordnews" onChange={this.onChange.bind(this)} />
                      <InputGroupAddon addonType="append">
                        <InputGroupText><i className="fa fa-asterisk"></i></InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {passwordnewsError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{passwordnewsError}</i> </p> : ''}
                  </FormGroup>
                  <FormGroup className="form-actions text-center">
                    <Button type="submit" size="sm" color="primary">Thay đổi mật khẩu</Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ChangePassword;
