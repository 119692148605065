import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import dataservice from '../../../services/dataservice';
import api from '../../../services/index';
import { connect } from 'react-redux'


class LoginStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      us: [],
      canLoad: true,
      category: '1',
      isLoading: true,
      inputUsername: '',
      inputPassword: '',
      storeToken: localStorage.getItem('stTK'),
      listNotify: []
    }
    this.updateUsername = this.updateUsername.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.handleEnter = this.handleEnter.bind(this)

    const tokenStore = localStorage.getItem('stTK')
    if (!tokenStore) this.props.history.push('/login')
  }
  async refreshToken() {
    let rs = await dataservice.refreshToken()
    if (rs.status == 403) {
      localStorage.clear()
      this.history.push('/login')
    } else {
      api.showMessage(rs.data.message, 'Lỗi ' + rs.status)
    }
  }

  async loginUser(username, password, token) {
    if(!username)return api.showMessage('Vui lòng không để trống .', 'Lỗi')
    if(!password)return api.showMessage('Vui lòng không để trống .', 'Lỗi')
    let rs = await dataservice.loginUser(username, password, token)
    if (rs.status == 200) {
      localStorage.setItem('userinfo', JSON.stringify(rs.data.loginInfo))
      localStorage.setItem('userTK', rs.data.loginInfo.token)
      window.location.reload()

      this.props.history.push('/')
      // window.location.reaload(true)
    } else {
      api.showMessage(rs.data.message, 'Lỗi ' + rs.status)

    }
  }

  componentDidMount() {
    // this.refreshToken()
    //  this.actFetchNotify()
    this.checkTK()
  }
  async actFetchNotify() {
    // var result = await dataservice.actGetSocketInit()
  }
  // async actFetchNotify(){
  //   var result = await dataservice.actGetSocketInit()
  //   console.log('ggg',result)
  // }
  updateUsername(e) {
    this.setState({
      inputUsername: e.target.value
    })
  }
  handleEnter(event) {
    if (event.key === 'Enter') {
          if(!this.state.inputPassword)return api.showMessage('Vui lòng không để trống .', 'Lỗi')
          if(!this.state.inputUsername)return api.showMessage('Vui lòng không để trống .', 'Lỗi')
      this.loginUser(this.state.inputUsername, this.state.inputPassword, this.state.storeToken)
    }
  }

  updatePassword(e) {
    this.setState({
      inputPassword: e.target.value
    })
  }
  checkTK() {
    let tokenStore = localStorage.getItem('stTK')
    if (!tokenStore) {
      this.props.history.push("/login");
    }
  }
  render() {
    // var { listNotify } = this.props;
    // listNotify = this.props.listNotify.notifies
    const tokenStore = localStorage.getItem('stTK')
    const tokenUser = localStorage.getItem('userTK')
    if (tokenStore == null) {
      this.props.history.push("/login");
    }
    if (tokenUser && tokenStore) {
      this.props.history.push("/");
    }
    let storeInfo
    if(localStorage.getItem('stinfo')){
      storeInfo = JSON.parse(localStorage.getItem('stinfo'))
    }
    else{
      storeInfo =''
    }
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1 style={{textAlign:'center',marginBottom:'20px'}}>Cửa hàng {storeInfo.name}</h1>
                      <h3>Nhân viên đăng nhập</h3>
                      <p className="text-muted">Đăng nhập với tài khoản nhân viên</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="Tên đăng nhập" onChange={this.updateUsername} onKeyPress={this.handleEnter} />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Mật khẩu" onChange={this.updatePassword} onKeyPress={this.handleEnter} />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4" onClick={() => this.loginUser(this.state.inputUsername, this.state.inputPassword, this.state.storeToken)}>Đăng nhập</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          {/* <Button color="link" className="px-0">Forgot password?</Button> */}
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                {/* <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.</p>
                      <Button color="primary" className="mt-3" active>Register Now!</Button>
                    </div>
                  </CardBody>
                </Card> */}
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userState: state.userState,
    listNotify: state.listNotify
  }
}
export default connect(mapStateToProps)(LoginStaff);
