import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import api from '../services/index';
class ConfirmBox extends Component {
    render() {
        return (
            <div className="static-modal">
                <Modal isOpen={this.props.show} >
                    <ModalHeader>
                        <Modal.Title>{this.props.titleConfirm ? this.props.titleConfirm : 'thong bao'}</Modal.Title>
                    </ModalHeader>

                    <ModalBody>{this.props.bodyConfirm}</ModalBody>

                    <ModalFooter>
                        <Button onClick={ this.props.bodyConfirmOK } >{this.props.titleBtnOK ? this.props.titleBtnOK : 'Ok'}</Button>
                        <Button onClick={this.props.bodyConfirmNO} >{this.props.titleBtnNO ? this.props.titleBtnNO : 'No'}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ConfirmBox
